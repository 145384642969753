<template>
    <b-modal title="Tracking list" v-model="showModal" size="xmd" modal-class="modal-primary" title-tag="h3" hide-footer
        @hidden="closeModal" centered>
        <div>
            <b-row>
                <b-col sm="12">
                    <b-table hover :items="trackingList" :fields="fields" responsive="sm" sticky-header></b-table>
                </b-col>
            </b-row>
        </div>
    </b-modal>
</template>
<script>
export default {
    props: {
        trackingList: {
            type: Array,
            default: null,
            required: true
        }
    },
    name: "ModalTrackingList",
    data() {
        return {
            showModal: true,
            fields: [
                {
                    key: 'status_connection',
                    label: 'Status',
                },
                {
                    key: 'comment',
                    label: 'Comment',
                },
                {
                    key: 'advisor_name',
                    label: 'User',
                },
                {
                    key: 'created_at',
                    label: 'Creation Date',
                }
            ],
        };
    },
    methods: {
        closeModal: function () {
            this.$emit("close-tracking-list", false);
        },
    },
    mounted() {
    }
};
</script>