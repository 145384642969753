var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-table',{ref:"refStatusChangeList",attrs:{"small":"","no-provider-filtering":"","items":_vm.myProvider,"fields":_vm.fields,"primary-key":"id","table-class":"text-nowrap","responsive":"sm","show-empty":"","sticky-header":"70vh","busy":_vm.isBusy,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"current-page":_vm.paginate.currentPage,"per-page":_vm.paginate.perPage},on:{"update:busy":function($event){_vm.isBusy=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-1"}),_c('strong',[_vm._v("Loading ...")])],1)]},proxy:true},{key:"cell(lead_name)",fn:function(data){return [_c('b-row',[_c('b-col',[_c('router-link',{class:_vm.textLink,attrs:{"to":{
            name: 'administration-dashboard',
            params: {
              idClient: data.item.account_id,
            },
          },"target":"_blank"}},[_vm._v(" "+_vm._s(data.item.lead_name))])],1)],1),_c('b-row',[_c('b-col',[_vm._v(" "+_vm._s(data.item.account)+" ")])],1),_c('b-row',[_c('b-col',[_vm._v(" "+_vm._s(data.item.eeuu_state)+" ")])],1),_c('b-row',[_c('b-col',{attrs:{"cols":"2"}},[(
            data.item.in_connection == 'CONNECTION' &&
            data.item.client_status_connection == 'RECOVERED'
          )?_c('div',[_c('FeatherIcon',{staticClass:"text-success",staticStyle:{"fill":"var(--success)","margin-top":"1px","margin-right":"4px"},attrs:{"icon":"SquareIcon","size":"20"}}),_vm._v(" Recovered ")],1):(
            data.item.in_connection == 'ADMINISTRATION' &&
            data.item.client_status_connection == 'TRANSITION'
          )?_c('div',[_c('FeatherIcon',{staticClass:"text-dark",staticStyle:{"fill":"var(--dark)","margin-top":"1px","margin-right":"4px"},attrs:{"icon":"SquareIcon","size":"20"}}),_vm._v(" Sent ")],1):(
            data.item.in_connection == 'ORIGIN' &&
            data.item.client_status_connection == 'REJECTED'
          )?_c('div',[_c('FeatherIcon',{staticClass:"text-info",staticStyle:{"fill":"var(--info)","margin-top":"1px","margin-right":"4px"},attrs:{"icon":"SquareIcon","size":"20"}}),_vm._v(" Rejected ")],1):(
            data.item.in_connection == 'CONNECTION' &&
            data.item.client_status_connection == 'RECYCLE'
          )?_c('div',[_c('FeatherIcon',{staticClass:"text-warning",staticStyle:{"fill":"var(--warning)","margin-top":"1px","margin-right":"4px"},attrs:{"icon":"SquareIcon","size":"20"}}),_vm._v(" Recycle ")],1):_vm._e(),(
            data.item.in_connection == 'CONNECTION' &&
            data.item.client_status_connection != 'RECOVERED' &&
            data.item.client_status_connection != 'RECYCLE'
          )?_c('div',[_c('FeatherIcon',{staticClass:"text-danger",staticStyle:{"fill":"var(--danger)","margin-top":"1px","margin-right":"4px"},attrs:{"icon":"SquareIcon","size":"20"}}),_vm._v(" Connection ")],1):_vm._e()])],1)]}},{key:"cell(charges)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("myMoney")(data.item.charges))+" ")]}},{key:"cell(monthly_amount)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("myMoney")(data.item.monthly_amount))+" ")]}},{key:"cell(last_payment)",fn:function(data){return [_c('b-row',[_c('b-col',[(data.item.state_lp !== 1)?_c('b-icon',{attrs:{"icon":"hourglass-bottom","aria-hidden":"true","variant":"success"}}):_c('b-icon',{attrs:{"icon":"hourglass-bottom","aria-hidden":"true","variant":"danger"}}),_vm._v(" "+_vm._s(data.item.last_payment == "-" ? "" : data.item.last_payment)+" ")],1)],1)]}},{key:"cell(srb)",fn:function(data){return [(
        data.item.type_payment == 'PA' &&
        data.item.status_payment == '1' &&
        data.item.srb == 'ACTIVE'
      )?_c('b-img',{staticStyle:{"margin-right":"5px"},attrs:{"title":'/Automatic/' + data.item.srb,"src":_vm.assetsImg + '/images/icons/paymenttype-auto.ico',"alt":""}}):_vm._e(),(
        data.item.type_payment == 'PA' &&
        data.item.status_payment == '0' &&
        data.item.srb != 'ACTIVE'
      )?_c('b-img',{staticStyle:{"margin-right":"5px"},attrs:{"title":'/Automatic/' + data.item.srb,"src":_vm.assetsImg + '/images/icons/paymenttype-auto-no.ico',"alt":""}}):_vm._e(),(
        data.item.type_payment == 'PA' &&
        data.item.status_payment == '1' &&
        data.item.srb != 'ACTIVE'
      )?_c('b-img',{staticStyle:{"margin-right":"5px"},attrs:{"title":'/Automatic/' + data.item.srb,"src":_vm.assetsImg + '/images/icons/paymenttype-auto-no.ico',"alt":""}}):_vm._e(),(data.item.type_payment == 'MA')?_c('b-img',{staticStyle:{"margin-right":"5px"},attrs:{"title":"Manual","src":_vm.assetsImg + '/images/icons/paymenttype-manual.ico',"alt":""}}):_vm._e(),(data.item.type_payment == 'OT')?_c('b-img',{staticStyle:{"margin-right":"5px"},attrs:{"title":"Others","src":_vm.assetsImg + '/images/icons/paymenttype-manual.ico',"alt":""}}):_vm._e(),(data.item.day_payment != null)?_c('span',[_vm._v("("+_vm._s(data.item.day_payment)+")")]):_vm._e()]}},{key:"cell(advisor_name)",fn:function(data){return [_c('div',[_c('feather-icon',{style:(data.item.advisor_state == 1
            ? 'background:green'
            : data.item.advisor_state == 2
            ? 'background:orange'
            : data.item.advisor_state == 3
            ? 'background:red'
            : 'background:#ccc'),attrs:{"id":"icon","title":data.item.advisor_state == 1
            ? 'Active'
            : data.item.advisor_state == 2
            ? 'Busy'
            : data.item.advisor_state == 3
            ? 'Away'
            : 'Offline',"icon":"CircleIcon","size":"13"}}),_vm._v(" "+_vm._s(data.item.advisor_name)+" ")],1)]}},{key:"cell(last_note)",fn:function(data){return [(data.item.s_note <= 1)?_c('b-badge',{staticStyle:{"padding":"6px 8px"},attrs:{"pill":"","variant":"light"}},[_c('feather-icon',{attrs:{"icon":"CalendarIcon"}}),_vm._v(" "+_vm._s(data.item.last_note)+" ")],1):(data.item.s_note <= 2)?_c('b-badge',{staticStyle:{"padding":"6px 8px"},attrs:{"pill":"","variant":"warning"}},[_c('feather-icon',{attrs:{"icon":"CalendarIcon"}}),_vm._v(" "+_vm._s(data.item.last_note)+" ")],1):_c('b-badge',{staticStyle:{"padding":"6px 8px"},attrs:{"pill":"","variant":"danger"}},[_c('feather-icon',{attrs:{"icon":"CalendarIcon"}}),_vm._v(" "+_vm._s(data.item.last_note)+" ")],1)]}},{key:"cell(task)",fn:function(data){return [_c('feather-icon',{attrs:{"id":"calendar","icon":"CalendarIcon","size":"20"}}),_c('div',{staticClass:"center-number-in-icon"},[_vm._v(" "+_vm._s(data.item.task)+" ")])]}},{key:"cell(created_at)",fn:function(data){return [_c('span',{},[_vm._v(" "+_vm._s(_vm._f("myGlobal")(data.item.created_at))+" ")])]}},{key:"cell(tracking)",fn:function(data){return [_c('div',{staticClass:"text-center text-primary"},[_c('FeatherIcon',{staticClass:"text-default cursor-pointer",attrs:{"icon":"EyeIcon","size":"20"},on:{"click":function($event){return _vm.openModalTrackingList(data.item.account_id)}}})],1)]}},(_vm.moduleId == 4 && (_vm.roleId == 1 || _vm.roleId == 2))?{key:"cell(actions)",fn:function(data){return [(
        data.item.in_connection == 'ADMINISTRATION' &&
        data.item.client_status_connection == 'TRANSITION'
      )?_c('b-dropdown',{attrs:{"size":"sm","variant":"link","no-caret":"","dropleft":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"align-middle text-body",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",value:('Send To Connection'),expression:"'Send To Connection'",modifiers:{"hover":true,"left":true}}],on:{"click":function($event){return _vm.openModal(data.item, 'SEND TO CONNECTION')}}},[_c('feather-icon',{attrs:{"icon":"SendIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Send To Connection")])],1),_c('b-dropdown-item',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",value:('Return To Module'),expression:"'Return To Module'",modifiers:{"hover":true,"left":true}}],on:{"click":function($event){return _vm.openModal(data.item, 'RETURN CLIENT')}}},[_c('feather-icon',{attrs:{"icon":"CornerUpRightIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Return To Module")])],1)],1):_vm._e()]}}:null],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }