export default [
    {
        key: "lead_name",
        label: "Name",
        visible: true
    },
    {
        key: "program",
        label: "Program",
        visible: true
    },
    {
        key: "charges",
        label: "Fee & Charges",
        visible: true
    },
    {
        key: "monthly_amount",
        label: "MP",
        visible: true,
    },
    {
        key: "last_payment",
        label: "LP",
        visible: true,
    },

    {
        key: "srb",
        label: "PT",
        visible: true,
    },
    {
        key: "advisor_name",
        label: "Advisor",
        visible: true,
    },
    {
        key: "last_note",
        label: "LN",
        visible: true,
    },
    {
        key: "task",
        label: "UT",
        visible: true,
    },
    {
        key: "created_at",
        label: "Creation Date",
        visible: true
    },
    {
        key: "state_hour",
        label: "St/Ad",
        visible: true
    },
    {
        key: "eeuu_state",
        label: "State",
        visible: true
    },
    {
        key: "tracking",
        label: "Tracking",
        visible: true
    },
]