<template>
  <b-table
    small
    no-provider-filtering
    ref="refStatusChangeList"
    :items="myProvider"
    :fields="fields"
    primary-key="id"
    table-class="text-nowrap"
    responsive="sm"
    show-empty
    sticky-header="70vh"
    :busy.sync="isBusy"
    :sort-by.sync="sortBy"
    :sort-desc.sync="sortDesc"
    :current-page="paginate.currentPage"
    :per-page="paginate.perPage"
  >
    <template #table-busy>
      <div class="text-center text-primary my-2">
        <b-spinner class="align-middle mr-1"></b-spinner>
        <strong>Loading ...</strong>
      </div>
    </template>
    <!-- Column NAME -->
    <template #cell(lead_name)="data">
      <b-row>
        <b-col>
          <router-link
            :class="textLink"
            :to="{
              name: 'administration-dashboard',
              params: {
                idClient: data.item.account_id,
              },
            }"
            target="_blank"
          >
            {{ data.item.lead_name }}</router-link
          >
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          {{ data.item.account }}
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          {{ data.item.eeuu_state }}
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="2">
          <div
            v-if="
              data.item.in_connection == 'CONNECTION' &&
              data.item.client_status_connection == 'RECOVERED'
            "
          >
            <FeatherIcon
              icon="SquareIcon"
              size="20"
              style="fill: var(--success); margin-top: 1px; margin-right: 4px"
              class="text-success"
            />
            Recovered
          </div>
          <!-- Sent status -->
          <div
            v-else-if="
              data.item.in_connection == 'ADMINISTRATION' &&
              data.item.client_status_connection == 'TRANSITION'
            "
          >
            <FeatherIcon
              icon="SquareIcon"
              size="20"
              style="fill: var(--dark); margin-top: 1px; margin-right: 4px"
              class="text-dark"
            />
            Sent
          </div>

          <!-- Rejected status -->
          <div
            v-else-if="
              data.item.in_connection == 'ORIGIN' &&
              data.item.client_status_connection == 'REJECTED'
            "
          >
            <FeatherIcon
              icon="SquareIcon"
              size="20"
              style="fill: var(--info); margin-top: 1px; margin-right: 4px"
              class="text-info"
            />
            Rejected
          </div>

          <!-- Recycle status -->
          <div
            v-else-if="
              data.item.in_connection == 'CONNECTION' &&
              data.item.client_status_connection == 'RECYCLE'
            "
          >
            <FeatherIcon
              icon="SquareIcon"
              size="20"
              style="fill: var(--warning); margin-top: 1px; margin-right: 4px"
              class="text-warning"
            />
            Recycle
          </div>

          <!-- Connection Status -->
          <div
            v-if="
              data.item.in_connection == 'CONNECTION' &&
              data.item.client_status_connection != 'RECOVERED' &&
              data.item.client_status_connection != 'RECYCLE'
            "
          >
            <FeatherIcon
              icon="SquareIcon"
              size="20"
              style="fill: var(--danger); margin-top: 1px; margin-right: 4px"
              class="text-danger"
            />
            Connection
          </div>
        </b-col>
      </b-row>
    </template>

    <!-- Column Charges -->
    <template #cell(charges)="data">
      {{ data.item.charges | myMoney }}
    </template>

    <!-- Column MP -->
    <template #cell(monthly_amount)="data">
      {{ data.item.monthly_amount | myMoney }}
    </template>

    <!-- Column LP -->
    <template #cell(last_payment)="data">
      <b-row>
        <b-col>
          <b-icon
            v-if="data.item.state_lp !== 1"
            icon="hourglass-bottom"
            aria-hidden="true"
            variant="success"
          ></b-icon>
          <b-icon
            v-else
            icon="hourglass-bottom"
            aria-hidden="true"
            variant="danger"
          ></b-icon>
          {{ data.item.last_payment == "-" ? "" : data.item.last_payment }}
        </b-col>
      </b-row>
    </template>

    <!-- Column PT  -->
    <template #cell(srb)="data">
      <b-img
        v-if="
          data.item.type_payment == 'PA' &&
          data.item.status_payment == '1' &&
          data.item.srb == 'ACTIVE'
        "
        :title="'/Automatic/' + data.item.srb"
        :src="assetsImg + '/images/icons/paymenttype-auto.ico'"
        alt=""
        style="margin-right: 5px"
      />

      <b-img
        v-if="
          data.item.type_payment == 'PA' &&
          data.item.status_payment == '0' &&
          data.item.srb != 'ACTIVE'
        "
        :title="'/Automatic/' + data.item.srb"
        :src="assetsImg + '/images/icons/paymenttype-auto-no.ico'"
        alt=""
        style="margin-right: 5px"
      />

      <b-img
        v-if="
          data.item.type_payment == 'PA' &&
          data.item.status_payment == '1' &&
          data.item.srb != 'ACTIVE'
        "
        :title="'/Automatic/' + data.item.srb"
        :src="assetsImg + '/images/icons/paymenttype-auto-no.ico'"
        alt=""
        style="margin-right: 5px"
      />

      <b-img
        v-if="data.item.type_payment == 'MA'"
        title="Manual"
        :src="assetsImg + '/images/icons/paymenttype-manual.ico'"
        alt=""
        style="margin-right: 5px"
      />

      <b-img
        v-if="data.item.type_payment == 'OT'"
        title="Others"
        :src="assetsImg + '/images/icons/paymenttype-manual.ico'"
        alt=""
        style="margin-right: 5px"
      />

      <span v-if="data.item.day_payment != null"
        >({{ data.item.day_payment }})</span
      >
    </template>

    <template #cell(advisor_name)="data">
      <div>
        <feather-icon
          id="icon"
          :title="
            data.item.advisor_state == 1
              ? 'Active'
              : data.item.advisor_state == 2
              ? 'Busy'
              : data.item.advisor_state == 3
              ? 'Away'
              : 'Offline'
          "
          icon="CircleIcon"
          size="13"
          :style="
            data.item.advisor_state == 1
              ? 'background:green'
              : data.item.advisor_state == 2
              ? 'background:orange'
              : data.item.advisor_state == 3
              ? 'background:red'
              : 'background:#ccc'
          "
        />
        {{ data.item.advisor_name }}
      </div>
    </template>

    <template #cell(last_note)="data">
      <b-badge
        v-if="data.item.s_note <= 1"
        pill
        style="padding: 6px 8px"
        variant="light"
      >
        <feather-icon icon="CalendarIcon" />
        {{ data.item.last_note }}
      </b-badge>

      <b-badge
        v-else-if="data.item.s_note <= 2"
        pill
        style="padding: 6px 8px"
        variant="warning"
      >
        <feather-icon icon="CalendarIcon" />
        {{ data.item.last_note }}
      </b-badge>

      <b-badge v-else pill style="padding: 6px 8px" variant="danger">
        <feather-icon icon="CalendarIcon" />
        {{ data.item.last_note }}
      </b-badge>
    </template>

    <template #cell(task)="data">
      <feather-icon id="calendar" icon="CalendarIcon" size="20" />
      <div class="center-number-in-icon">
        {{ data.item.task }}
      </div>
    </template>

    <template #cell(created_at)="data">
      <span class="">
        {{ data.item.created_at | myGlobal }}
      </span>
    </template>

    <template #cell(tracking)="data">
      <div class="text-center text-primary">
        <FeatherIcon
          icon="EyeIcon"
          size="20"
          class="text-default cursor-pointer"
          @click="openModalTrackingList(data.item.account_id)"
        >
        </FeatherIcon>
      </div>
    </template>

    <template
      #cell(actions)="data"
      v-if="moduleId == 4 && (roleId == 1 || roleId == 2)"
    >
      <b-dropdown
        size="sm"
        variant="link"
        no-caret
        dropleft
        v-if="
          data.item.in_connection == 'ADMINISTRATION' &&
          data.item.client_status_connection == 'TRANSITION'
        "
      >
        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="16"
            class="align-middle text-body"
          />
        </template>

        <b-dropdown-item
          v-b-tooltip.hover.left="'Send To Connection'"
          @click="openModal(data.item, 'SEND TO CONNECTION')"
        >
          <feather-icon icon="SendIcon" />
          <span class="align-middle ml-50">Send To Connection</span>
        </b-dropdown-item>
        <!-- Send To Origin Module -->
        <b-dropdown-item
          v-b-tooltip.hover.left="'Return To Module'"
          @click="openModal(data.item, 'RETURN CLIENT')"
        >
          <feather-icon icon="CornerUpRightIcon" />
          <span class="align-middle ml-50">Return To Module</span>
        </b-dropdown-item>
      </b-dropdown>
    </template>
  </b-table>
</template>

<script>
export default {
  name: "StatusChangeTable",
  props: {
    myProvider: {
      type: Function,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    sortBy: {
      type: String,
      required: true,
    },
    sortDesc: {
      type: Boolean,
      required: true,
    },
    paginate: {
      type: Object,
      required: true,
    },
    moduleId: {
      type: Number,
      required: true,
    },
    roleId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isBusy: false,
      assetsImg: process.env.VUE_APP_BASE_URL_ASSETS,
    };
  },
  methods: {
    openModalTrackingList: function (accountId) {
      this.$emit("show-tracking-list", accountId);
    },
    openModal: function (client, type) {
      let data = {
        client: client,
        type: type,
      };
      this.$emit("show-send-or-return-client", data);
    },
    reloadTable: function () {
      this.$refs["refStatusChangeList"].refresh();
    },
  },
};
</script>
<style scoped>
#icon {
  border-radius: 50%;
  margin-bottom: 2px;
  margin-right: 5px;
}

.center-number-in-icon {
  position: relative;
  bottom: 15px;
  left: 6.7px;
  font-size: 85%;
  font-weight: bold;
}
</style>
